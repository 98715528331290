<template>
  <v-container
    id="product-detail"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-timeline
          align-top
          dense
        >
          <v-timeline-item
            color="grey"
            :icon="!$vuetify.breakpoint.mobile ? 'mdi-information-variant' : ''"
            fill-dot
            :hide-dot="$vuetify.breakpoint.mobile"
            :small="$vuetify.breakpoint.mobile"
          >
            <v-card
              color="grey"
            >
              <v-card-title
                class="pa-3 white--text"
              >
                Product Info
                <v-spacer />
                <v-btn
                  elevation="12"
                  icon
                  @click="cameraDialog = true"
                >
                  <v-icon>mdi-qrcode</v-icon>
                </v-btn>
                <v-menu
                  :disabled="loading || !item"
                  open-on-hover
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      elevation="12"
                      :color="selected ? 'primary' : 'white'"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-heart</v-icon>
                    </v-btn>
                  </template>
                  <v-list
                    dense
                    class="text-right"
                  >
                    <v-list-item @click="toggleProductsList(item)">
                      <v-list-item-title>
                        <template
                          v-if="selected"
                        >
                          Remove from products list <v-icon>mdi-heart-off</v-icon>
                        </template>
                        <template
                          v-else
                        >
                          Add to products list <v-icon>mdi-heart</v-icon>
                        </template>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      :disabled="!b2bSelectedProductsList || b2bSelectedProductsList.length == 0"
                      to="/products-list"
                    >
                      <v-list-item-title>
                        Go to your products list <v-icon>mdi-clipboard-list</v-icon>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="cameraDialog = true"
                    >
                      <v-list-item-title>
                        Scan new product <v-icon>mdi-qrcode</v-icon>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>

              <v-row class="black ma-0">
                <v-col
                  cols="12"
                  md="6"
                >
                  <base-material-card
                    color="grey"
                    inline
                    class="mt-4 px-5 py-3"
                  >
                    <template v-slot:heading>
                      <div class="display-1 font-weight-light">
                        Product Details
                      </div>
                    </template>
                    <v-skeleton-loader
                      v-if="loading"
                      type="heading, chip"
                    />
                    <template v-if="item">
                      <h1
                        v-if="item.Item_Description"
                        class="mt-2"
                      >
                        {{ item.Item_Description }}
                      </h1>
                      <h4
                        v-if="item.Item_Code"
                        class="text--disabled"
                      >
                        {{ item.Item_Code }}
                      </h4>
                      <h3
                        v-if="item.Item_Currency"
                        class="text--disabled"
                      >
                        Price: {{ item.Item_Price }}
                        <template v-if="item.Item_Price_Um">
                          {{ item.Item_Currency }}/{{ item.Item_Price_Um }}
                        </template>
                        <template v-else>
                          {{ item.Item_Currency }}
                        </template>
                      </h3>
                      <v-btn
                        v-if="item.Link"
                        small
                        class="mt-2"
                        :href="item.Link"
                        target="blank"
                      >
                        Learn More
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                  </base-material-card>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <base-material-card
                    color="grey"
                    inline
                    class="no-padding"
                    :class="!$vuetify.breakpoint.xs ? 'mt-4 px-5 py-3' : ''"
                  >
                    <template v-slot:heading>
                      <div class="display-1 font-weight-light">
                        Technical Specs
                      </div>
                    </template>
                    <v-card-text>
                      <v-skeleton-loader
                        v-if="loading"
                        class="mx-auto"
                        type="text@3"
                        :types="{ 'table-row': 'table-cell@2' }"
                        width="50%"
                      />
                      <v-simple-table
                        v-if="item"
                        dense
                      >
                        <tbody>
                          <tr v-if="item.Item_Height">
                            <td
                              class="font-weight-bold"
                              :class="{'text-right': !$vuetify.breakpoint.xs, 'title': $vuetify.breakpoint.xs}"
                            >
                              Height
                            </td>
                            <td class="text-left">
                              {{ item.Item_Height_Um }} {{ item.Item_Height }}
                            </td>
                          </tr>
                          <tr v-if="item.Item_Weight">
                            <td
                              class="font-weight-bold"
                              :class="{'text-right': !$vuetify.breakpoint.xs, 'title': $vuetify.breakpoint.xs}"
                            >
                              Weight
                            </td>
                            <td class="text-left">
                              {{ item.Item_Weight_Um | superscriptfixmB2 }} {{ item.Item_Weight }}
                            </td>
                          </tr>
                          <tr v-if="item.Item_Thickness">
                            <td
                              class="font-weight-bold"
                              :class="{'text-right': !$vuetify.breakpoint.xs, 'title': $vuetify.breakpoint.xs}"
                            >
                              Thickness
                            </td>
                            <td class="text-left">
                              {{ item.Item_Thickness_Um }} {{ item.Item_Thickness }}
                            </td>
                          </tr>
                          <tr v-if="item.Item_Standard_Formats">
                            <td
                              class="font-weight-bold"
                              :class="{'text-right': !$vuetify.breakpoint.xs, 'title': $vuetify.breakpoint.xs}"
                            >
                              Std. Formats
                            </td>
                            <td class="text-left">
                              {{ item.Item_Standard_Formats }}
                            </td>
                          </tr>
                          <tr v-if="item.Item_Technical_Finished">
                            <td
                              class="font-weight-bold"
                              :class="{'text-right': !$vuetify.breakpoint.xs, 'title': $vuetify.breakpoint.xs}"
                            >
                              Total Composition
                            </td>
                            <td class="text-left">
                              {{ item.Item_Technical_Finished }}
                            </td>
                          </tr>
                          <tr v-if="item.Item_Technical_Skin">
                            <td
                              class="font-weight-bold"
                              :class="{'text-right': !$vuetify.breakpoint.xs, 'title': $vuetify.breakpoint.xs}"
                            >
                              Skin Composition
                            </td>
                            <td class="text-left">
                              {{ item.Item_Technical_Skin }}
                            </td>
                          </tr>
                          <tr v-if="item.Item_Technical_Backing">
                            <td
                              class="font-weight-bold"
                              :class="{'text-right': !$vuetify.breakpoint.xs, 'title': $vuetify.breakpoint.xs}"
                            >
                              Backing Composition
                            </td>
                            <td class="text-left">
                              {{ item.Item_Technical_Backing }}
                            </td>
                          </tr>
                          <tr v-if="item.Item_HiMelt_Composition">
                            <td
                              class="font-weight-bold"
                              :class="{'text-right': !$vuetify.breakpoint.xs, 'title': $vuetify.breakpoint.xs}"
                            >
                              HiMelt Composition
                            </td>
                            <td class="text-left">
                              {{ item.Item_HiMelt_Composition }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-card-text>
                  </base-material-card>
                </v-col>
              </v-row>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            color="grey"
            :icon="!$vuetify.breakpoint.mobile ? 'mdi-format-color-fill' : undefined"
            fill-dot
            :hide-dot="$vuetify.breakpoint.mobile"
            :small="$vuetify.breakpoint.mobile"
          >
            <v-card
              color="grey"
            >
              <v-card-title
                class="pa-3 white--text"
              >
                Available Colors
                <v-spacer />
                <v-switch
                  v-if="item"
                  v-model="enableFilter"
                  class="mt-0"
                  hide-details
                  label="Filter colors"
                />
              </v-card-title>

              <v-row
                v-if="enableFilter"
                class="grey ma-0"
              >
                <v-col
                  cols="12"
                >
                  <v-switch
                    v-model="filter.onlyAvailable"
                    class="mt-0"
                    hide-details
                    label="Show only available"
                    :value="true"
                  />
                  <v-slider
                    v-model="filter.minAvailable"
                    :disabled="!filter.onlyAvailable"
                    min="1"
                    :max="filter.qtyTicksLabels.length"
                    step="1"
                    ticks="always"
                    tick-size="5"
                    :tick-labels="filter.qtyTicksLabels"
                    :label="!$vuetify.breakpoint.mobile ? `Minimum quantity available (${ item.Item_Base_Um })` : item.Item_Base_Um"
                  />
                  <v-slider
                    v-model="filter.weeksAvailable"
                    :disabled="!filter.onlyAvailable"
                    min="1"
                    :max="filter.weekTicksLabels.length"
                    step="1"
                    ticks="always"
                    tick-size="5"
                    :tick-labels="filter.weekTicksLabels"
                    :label="!$vuetify.breakpoint.mobile ? 'Available in # weeks' : 'In # weeks'"
                  />
                </v-col>
              </v-row>

              <v-row class="black ma-0">
                <v-col
                  cols="12"
                >
                  <v-skeleton-loader
                    v-if="loading"
                    type="table-thead@3"
                    width="90%"
                    :types="{ 'table-thead': $vuetify.breakpoint.mobile ? 'chip@4' : 'chip@9' }"
                  />
                  <template v-if="item">
                    <div class="title">
                      Tap on color to display available quantity and other relevant data
                    </div>
                    <v-btn
                      v-for="(variant, i) in filteredVariants"
                      :key="i"
                      elevation="1"
                      :color="variant.Variant_Code === 'BIANCO' ? 'white' : 'undefined'"
                      :outlined="variant.Variant_Code === 'NERO'"
                      class="ma-1"
                      :small="$vuetify.breakpoint.mobile"
                      :style="`background-image: url(https://ds0vj0o1dofxd.cloudfront.net/variants/${variant.Variant_Code}-TPG.png); background-size: cover;`"
                      @click="dialog = true; curVariant = variant; selectedVariant = checkSelected(item, variant)"
                    >
                      {{ variant.Variant_Code }}
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="500"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card class="text-center">
        <v-card-title>
          Color details
          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="dialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text v-if="curVariant">
          <v-row
            class="justify-end mb-2"
          >
            <v-btn
              elevation="12"
              icon
              @click="cameraDialog = true"
            >
              <v-icon>mdi-qrcode</v-icon>
            </v-btn>
            <v-menu
              :disabled="loading"
              open-on-hover
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="12"
                  :color="selectedVariant ? 'primary' : 'white'"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
              </template>
              <v-list
                dense
                class="text-right"
              >
                <v-list-item @click="toggleProductsListVariant(item, curVariant)">
                  <v-list-item-title>
                    <template
                      v-if="selectedVariant"
                    >
                      Remove from products list <v-icon>mdi-heart-off</v-icon>
                    </template>
                    <template
                      v-else
                    >
                      Add to products list <v-icon>mdi-heart</v-icon>
                    </template>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="!b2bSelectedProductsList || b2bSelectedProductsList.length == 0"
                  to="/products-list"
                >
                  <v-list-item-title>
                    Go to your products list <v-icon>mdi-clipboard-list</v-icon>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>

          <div
            :style="`min-height: 400px; background-image: url(https://ds0vj0o1dofxd.cloudfront.net/variants/${curVariant.Variant_Code}-TPG.png); background-size: cover;`"
          />

          <v-simple-table
            dense
          >
            <tbody>
              <tr v-if="curVariant.Variant_Code">
                <td class="font-weight-bold text-right">
                  Color Code
                </td>
                <td class="text-left">
                  {{ curVariant.Variant_Code }}
                </td>
              </tr>
              <tr v-if="curVariant.Variant_Description">
                <td class="font-weight-bold text-right">
                  Color Name
                </td>
                <td class="text-left">
                  {{ curVariant.Variant_Description }}
                </td>
              </tr>
              <tr v-if="curVariant.Variant_Quantity_Available">
                <td
                  class="font-weight-bold text-right"
                >
                  Available Qty
                </td>
                <td class="text-left">
                  {{ curVariant.Variant_Um }} {{ curVariant.Variant_Quantity_Available }}
                </td>
              </tr>
              <tr v-if="curVariant.Variant_Currency">
                <td
                  class="font-weight-bold text-right"
                >
                  Price
                </td>
                <td class="text-left">
                  {{ curVariant.Variant_Price }}
                  <template v-if="curVariant.Variant_Price_Um">
                    {{ curVariant.Variant_Currency }}/{{ curVariant.Variant_Price_Um }}
                  </template>
                  <template v-else>
                    {{ curVariant.Variant_Currency }}
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="error"
            text
            @click="dialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { API } from 'aws-amplify'
  // Components
  export default {
    name: 'ProductDetails',

    data: () => ({
      dialog: false,
      loading: true,
      curVariant: undefined,
      productDetails: undefined,
      item: undefined,
      variants: undefined,
      enableFilter: false,
      filter: {
        onlyAvailable: false,
        minAvailable: 0,
        weeksAvailable: 0,
        qtyTicksLabels: [
          '1',
          '100',
          '500',
          '1000',
        ],
        weekTicksLabels: [
          '1',
          '2',
          '3',
          '4',
          '6',
          '8',
          '10',
          '12',
        ],
      },
      selected: false,
      selectedVariant: false,
      b2bSelectedProductsList: JSON.parse(localStorage.b2bSelectedProductsList || '[]'),
    }),

    computed: {
      filteredVariants () {
        const qtyMin = this.filter.qtyTicksLabels[this.filter.minAvailable - 1]
        const weekAvail = this.filter.weekTicksLabels[this.filter.weeksAvailable - 1]
        return this.variants.filter(variant => {
          if (!this.enableFilter) {
            return true
          }
          return !this.filter.onlyAvailable ||
            ((this.filter.onlyAvailable && variant.Variant_Quantity_Available > 0) &&
              (this.filter.onlyAvailable && qtyMin && variant.Variant_Quantity_Available >= qtyMin)
            ) ||
            (this.filter.onlyAvailable && weekAvail && variant[`Period_0${this.filter.weeksAvailable}_Quantity`] >= qtyMin)
        })
      },
      cameraDialog: {
        get () {
          return this.$store.state.cameraDialog
        },
        set (val) {
          this.$store.commit('SET_CAMERA_DIALOG', val)
        },
      },
    },

    created () {
      this.loadProductDetails(this.$route.params.product_id)
    },

    async beforeRouteUpdate (to, from, next) {
      this.loadProductDetails(to.params.product_id)
      next()
    },

    methods: {
      async loadProductDetails (productId) {
        const apiName = 'apiPublic'
        const path = '/execute/qr.code@item+qr.code@variants'
        const myInit = {
          queryStringParameters: {
            ITEM_CODE: productId,
          },
        }

        API
          .get(apiName, path, myInit)
          .then(response => {
            this.loading = false
            this.productDetails = response
            this.item = this.productDetails.item.recordset[0]
            this.variants = this.productDetails.variants.recordset
            if (!this.item) {
              this.$router.push(`/product-not-found/${this.$route.params.product_id}`)
            } else {
              this.enableFilter = this.filter.onlyAvailable = this.$route.query.minQty != null
              if (this.enableFilter) {
                this.filter.minAvailable = this.filter.qtyTicksLabels.indexOf(this.$route.query.minQty) + 1
              }
              this.addToRecentlyViewed(this.item)
              this.selected = this.checkSelected(this.item)
            }
          })
          .catch(error => {
            /* eslint no-console: 0 */
            console.log(error.response)
          })
      },
      addToRecentlyViewed (item) {
        if (item) {
          let list = []
          if (localStorage.b2bLastViewedProductsList) {
            list = JSON.parse(localStorage.b2bLastViewedProductsList)
          }
          const currProduct = {
            description: item.Item_Description,
            url: window.location.pathname + window.location.hash,
            code: item.Item_Code,
          }
          if (!list.some(item => item.code === currProduct.code)) {
            list.push(currProduct)
          }
          localStorage.b2bLastViewedProductsList = JSON.stringify(list)
        }
      },
      checkSelected (item, variant) {
        if (this.item) {
          let list = []
          if (localStorage.b2bSelectedProductsList) {
            list = JSON.parse(localStorage.b2bSelectedProductsList)
          }
          const currProduct = {
            id: item.Item_Code + (variant ? ('§' + variant.Variant_Code) : ''),
          }
          return list.some(item => item.id === currProduct.id)
        }
        return false
      },
      toggleProductsList (item) {
        if (item) {
          if (localStorage.b2bSelectedProductsList) {
            this.b2bSelectedProductsList = JSON.parse(localStorage.b2bSelectedProductsList)
          }
          let list = this.b2bSelectedProductsList
          const currProduct = {
            id: item.Item_Code,
            description: item.Item_Description,
            url: window.location.pathname + window.location.hash,
            code: item.Item_Code,
            domain: window.location.origin,
          }
          if (!list.some(item => item.id === currProduct.id)) {
            list.push(currProduct)
            this.selected = true
          } else {
            list = list.filter(item => item.id !== currProduct.id)
            this.selected = false
          }
          localStorage.b2bSelectedProductsList = JSON.stringify(list)
          this.b2bSelectedProductsList = list
        }
      },
      toggleProductsListVariant (item, variant) {
        // debugger
        if (localStorage.b2bSelectedProductsList) {
          this.b2bSelectedProductsList = JSON.parse(localStorage.b2bSelectedProductsList)
        }
        let list = this.b2bSelectedProductsList
        const currProduct = {
          id: item.Item_Code + '§' + variant.Variant_Code,
          description: item.Item_Description,
          url: window.location.pathname + window.location.hash,
          code: item.Item_Code,
          variant: {
            code: variant.Variant_Code,
            description: variant.Variant_Description,
          },
          domain: window.location.origin,
        }
        if (!list.some(item => item.id === currProduct.id)) {
          list.push(currProduct)
          this.selectedVariant = true
        } else {
          list = list.filter(item => item.id !== currProduct.id)
          this.selectedVariant = false
        }
        localStorage.b2bSelectedProductsList = JSON.stringify(list)
        this.b2bSelectedProductsList = list
      },
      onQrcodeDecode (decodedString) {
        const productRegExp = new RegExp('(.*.lorenzinet.com|localhost|192.168.*)/products/.*')
        if (productRegExp.test(decodedString)) {
          this.cameraDialog = false
          window.location.href = decodedString
        }
      },
    },

  }
</script>

<style lang="scss" scoped>
@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-card--material.no-padding .v-card__text {
      padding-left: 0px;
      padding-right: 0px;
  }
}
</style>
